<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ProfileEvaluationService from "@/services/ProfileEvaluationService";
import { useLoading } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

import NoEnterpriseDataCandidateReportCover from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/NoEnterpriseDataCandidateReportCover.vue";
import CandidateReport6Cultures from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReport6Cultures.vue";
import NoEnterpriseDataCandidateReportExecutiveSummary from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/NoEnterpriseDataCandidateReportExecutiveSummary.vue";
import CandidateReportIdealFit from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportIdealFit.vue";
import CandidateReportMindsetOf from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportMindsetOf.vue";
import CandidateReportPredominantValues from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportPredominantValues.vue";
import CandidateReportAppendix1 from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportAppendix1.vue";
import CandidateReportAppendix2 from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportAppendix2.vue";
import CandidateReportBack from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportBack.vue";

import DictionaryForReport from "@/globals/DictionaryForReport";

const AWS = require("aws-sdk");
const store = useStore();
const pdfUrl = ref("");
const showReport = ref(true);
const $loading = useLoading({});
let loadingLayer = null;

const route = useRoute();

const reportData = reactive({
  name: "-",
  token: "-",
  email: "-",
  archInd: [],
  archTitle: [],
  archMax1: "-",
  archMax2: "-",
  archMax1Text: "-",
  archMax2Text: "-",
  archMax1TextSummary: "-",
  archMax2TextSummary: "-",
  archMax1Area: "-",
  archMax1AreaProcessed: [],
  archMax2Area: "-",
  archMax2AreaProcessed: [],
  archMin1Area: "-",
  archMin1AreaProcessed: [],
  archMin2Area: "-",
  archMin2AreaProcessed: [],
  valuesTitle: [],
  valuesText: [],
  valuesCompetences: [],
  integrityScore16: [],
  integrityIndicator: "-",
  integrityRecommendation: "-",
  integrityRecommendationText: "-",
  integrityValues: [],
  integrityValBehaviour: [],
  integrityProb: [],
  mindsetFitIdealPercent: "-",
  paramName: "-",
  TxtCuadrante: "-",
  tokenText: "",
  predominance: [],
  predominantValues: [],
  mostLikelyBehaviours: [],
  ParamBehaviours: [],
  archValuesChartSeries: [
    { name: "Predominance Values", data: [10, 20, 30, 40, 50, 60] },
  ],
  candidateIntegritySeries: [
    { name: "Cultura de Integridad en la Persona", data: [1, 2, 3, 4, 5, 6] },
  ],
  KPITTransformacional: "0",
  KPITTransaccional: "0",
  series_33: [
    {
      name: "Liderazgo transformacional",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10],
    },
  ],
  series_44: [
    {
      name: "Liderazgo transaccional",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10],
    },
  ],
  series_6_IdealVsPersona: [
    { name: "", data: [10, 20, 30, 40, 50, 60] },
    { name: "", data: [10, 20, 30, 40, 50, 60] },
  ],
  series_36_IdealVsPersona: [
    { name: "", data: Array(36).fill(0) },
    { name: "", data: Array(36).fill(0) },
  ],
  opcionesReport: [true, true, true],
});

const routeParams = route.params;
const currentLanguage = computed(() => store.getters.currentLanguage);

const setLanguageFromRoute = () => {
  const language = route.params.language || "es"; // Default to 'es' if no language param
  store.commit("SET_LANGUAGE", language);
};

// Watch for changes in route params and update store
watch(
  () => routeParams.language,
  (newLanguage) => {
    store.commit("SET_LANGUAGE", newLanguage);
  }
);

const getDictionaryValue = (key) => {
  return computed(() => DictionaryForReport[currentLanguage.value][key]);
};

const [IdealValues, PersonValues, IdealMindset, PersonMindset] = [
  97, 98, 99, 122, 123, 124, 125,
].map(getDictionaryValue);

const fetchReportData = async (candidateToken, language) => {
  try {
    const { data } = await ProfileEvaluationService.getCandidateReportData(
      candidateToken,
      language
    );

    console.log("Data received: ", data);

    if (data && data.cultura) {
      Object.assign(reportData, {
        name: data.nombre || "-",
        token: data.token || "-",
        email: data.email || "-",
        archInd: data.cultura.ARQind || [],
        archTitle: data.arqTITULO || [],
        archMax1: data.ARQmax1 || "-",
        archMax2: data.ARQmax2 || "-",
        archMax1Text: data.ARQmax1texto || "-",
        archMax2Text: data.ARQmax2texto || "-",
        archMax1TextSummary: data.ARQmax1textoResumen || "-",
        archMax2TextSummary: data.ARQmax2textoResumen || "-",
        archMax1Area: data.ARQmax1area || "-",
        archMax1AreaProcessed: convertToList(data.ARQmax1area) || [],
        archMax2Area: data.ARQmax2area || "-",
        archMax2AreaProcessed: convertToList(data.ARQmax2area) || [],
        archMin1Area: data.ARQmin1area || "-",
        archMin1AreaProcessed: convertToList(data.ARQmin1area) || [],
        archMin2Area: data.ARQmin2area || "-",
        archMin2AreaProcessed: convertToList(data.ARQmin2area) || [],
        valuesTitle: data.valoresTITULO || [],
        valuesText: data.valoresTEXTO || [],
        valuesCompetences: data.valoresCOMPETENCIAS || [],
        integrityScore16: data.IntegridadPuntuacion16 || [],
        integrityIndicator: data.IntegridadIndicador || "-",
        integrityRecommendation: data.IntegridadRecomendacion || "-",
        integrityRecommendationText: data.IntegridadRecomendTEXTO || "-",
        integrityValues: data.IntegridadValores || [],
        integrityValBehaviour: data.IntegridadValCOMPORTAMIENTOS || [],
        integrityProb: data.IntegridadProb || [],
        mindsetFitIdealPercent: data.param?.mindsetFit,
        paramName: data.proyectoParam || "-",
        TxtCuadrante: data.textoCuadrante || "",
        tokenText: data.token ? data.token.replace("CF-", "") : "",
        predominance: processPredominance(data),
        predominantValues: processPredominantValues(data),
        mostLikelyBehaviours: processMostLikelyBehaviours(data),
        ParamBehaviours: processParamBehaviours(data),
        KPITTransformacional: data.KPItransformacional,
        KPITTransaccional: data.KPItransaccional,
        archValuesChartSeries: [
          {
            name: "Predominance Values",
            data: data.cultura.ARQind || [],
          },
        ],
        candidateIntegritySeries: [
          {
            name: "Cultura de Integridad en la Persona",
            data: data.IntegridadPuntuacion16 || [],
          },
        ],
        series_33: [
          {
            name: "Liderazgo transformacional",
            data: data.transfo.LiderPuntuacion,
          },
        ],
        series_44: [
          {
            name: "Liderazgo transaccional",
            data: data.transac.LiderPuntuacion,
          },
        ],
        series_6_IdealVsPersona: [
          {
            name: IdealMindset.value,
            data: data.param?.pApar || [0, 0, 0, 0, 0, 0],
          },
          {
            name: PersonMindset.value,
            data: data.cultura?.ARQind || [0, 0, 0, 0, 0, 0],
          },
        ],
        series_36_IdealVsPersona: [
          {
            name: IdealValues.value,
            data: data.param?.mVpar || Array(36).fill(0),
          },
          {
            name: PersonValues.value,
            data: data.cultura?.VALind || Array(36).fill(0),
          },
        ],
      });
    }
    // Indicar que los datos han sido obtenidos
    window.dataFetched = true;
  } catch (error) {
    console.error("Error fetching report data:", error);
    window.dataFetched = false;
  }
};

// Configura la región y las credenciales de AWS
AWS.config.update({
  accessKeyId: "AKIAZO2KPRALOH7S2EK3",
  secretAccessKey: "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",
  region: "eu-south-2",
});

// Crea una instancia del cliente de S3
const s3 = new AWS.S3();

// Función para subir el PDF a S3
async function uploadPDFToS3(buffer, bucketName, key) {
  const params = {
    Bucket: bucketName,
    Key: key,
    Body: buffer,
    ContentType: "application/pdf",
  };

  return new Promise((resolve, reject) => {
    s3.upload(params, (err, data) => {
      if (err) {
        console.error("Error uploading to S3:", err);
        reject(err);
      } else {
        console.log("Upload Success", data);
        resolve(data.Location);
      }
    });
  });
}

const generatePDF = async () => {
  try {
    await new Promise((resolve) => setTimeout(resolve, 2200));

    const reportElement = document.querySelector("#candidate-report");
    if (reportElement) {
      const canvas = await html2canvas(reportElement, {
        scale: window.devicePixelRatio > 2 ? 2 : window.devicePixelRatio,
        width: 794,
        windowWidth: 794,
        logging: true,
      });

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "a4",
        compress: true,
        putOnlyUsedFonts: true,
        hotfixes: ["px_scaling"],
      });

      const pageHeight = pdf.internal.pageSize.getHeight();
      let canvasHeight = canvas.height;
      let position = 0;

      while (canvasHeight > 0) {
        pdf.addImage(
          canvas.toDataURL("image/jpeg", 1.0),
          "JPEG",
          0,
          position,
          canvas.width,
          canvas.height
        );
        canvasHeight -= pageHeight;
        position -= pageHeight;
        if (canvasHeight > 0) pdf.addPage();
      }

      // Convertir el PDF a un Blob y luego a un Buffer para subir a S3
      const pdfBlob = pdf.output("blob");
      const arrayBuffer = await pdfBlob.arrayBuffer();
      const buffer = new Uint8Array(arrayBuffer);

      const bucketName = "cfs-report";
      const s3Key = `integra-TT/${reportData.token}.pdf`;
      const s3Url = await uploadPDFToS3(buffer, bucketName, s3Key);

      pdfUrl.value = s3Url;
      showReport.value = false;
      console.log("PDF URL:", pdfUrl.value);
      document.title = s3Url;
    }
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};

const processPredominance = (data) => {
  if (!data.arqTITULO || !data.cultura || !data.cultura.ARQind) {
    return [];
  }
  return data.arqTITULO
    .map((title, index) => ({
      title,
      value: data.cultura.ARQind[index],
    }))
    .sort((a, b) => b.value - a.value);
};

const processPredominantValues = (data) => {
  if (!data.valoresTITULO || !data.valoresTEXTO || !data.valoresCOMPETENCIAS) {
    return [];
  }
  return data.valoresTITULO.map((title, index) => ({
    title,
    text: data.valoresTEXTO[index],
    competences: data.valoresCOMPETENCIAS[index],
  }));
};

const processMostLikelyBehaviours = (data) => {
  if (
    !data.IntegridadValores ||
    !data.IntegridadValCOMPORTAMIENTOS ||
    !data.IntegridadProb
  ) {
    return [];
  }
  return data.IntegridadValores.map((value, index) => ({
    value,
    behaviour: data.IntegridadValCOMPORTAMIENTOS[index],
    probability: data.IntegridadProb[index],
  }));
};
function processParamBehaviours(data) {
  let ParamBehaviours = [];

  for (var index in data.ParametrizacionValores) {
    ParamBehaviours.push({
      value: data.ParametrizacionValores[index],
      behaviour: data.ParametrizacionComportamientos[index],
      probability: data.ParametrizacionProb[index],
    });
  }

  return ParamBehaviours;
}

const convertToList = (text) => {
  if (!text) {
    return [];
  }
  return text.split(",").map((item) => item.trim());
};

onMounted(async () => {
  setLanguageFromRoute(); // Set the initial language from route params
  loadingLayer = $loading.show({ color: "#34495e", opacity: "0.7" });
  const { candidateToken, language } = route.params;
  await fetchReportData(candidateToken, language);
  await generatePDF();
  loadingLayer.hide();
});
</script>

<template>
  <div>
    <div v-if="showReport" id="candidate-report" class="report-tpl-container">
      <NoEnterpriseDataCandidateReportCover :reportData="reportData" />
      <CandidateReport6Cultures />
      <NoEnterpriseDataCandidateReportExecutiveSummary
        :reportData="reportData"
      />
      <CandidateReportMindsetOf :reportData="reportData" />
      <CandidateReportPredominantValues :reportData="reportData" />
      <CandidateReportIdealFit
        v-if="reportData.paramName !== '-'"
        :reportData="reportData"
      />
      <CandidateReportAppendix2 :reportData="reportData" />
      <CandidateReportAppendix1 :reportData="reportData" />
      <CandidateReportBack />
    </div>
    <div v-if="pdfUrl">
      <object :data="pdfUrl" type="application/pdf" width="100%" height="900px">
        <embed :src="pdfUrl" type="application/pdf" />
      </object>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/candidate-report.scss";
</style>
